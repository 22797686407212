import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ApologyPage = () => {
  const router = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [countdown, setCountdown] = useState('');
  const [audio] = useState(new Audio('/images/audio.mp3'));
  const [isMuted, setIsMuted] = useState(false);
  const [showClickHint, setShowClickHint] = useState(true);
  const targetDate = new Date(new Date().getFullYear(), 10, 19);

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleNoClick = () => {
    alert('Thank you, pookie! I knew you have a big heart ❤️');
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audio.muted = !audio.muted;
  };

  // Update countdown timer
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setCountdown('🎉 Happy Birthday! 🎉');
        clearInterval(interval);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  // Enhanced audio handling
  useEffect(() => {
    const playAudio = async () => {
      try {
        audio.loop = true;
        await audio.play();
        setShowClickHint(false);
      } catch (err) {
        console.log('Autoplay blocked. Adding click event listener...');
        
        const handleClick = async () => {
          try {
            await audio.play();
            setShowClickHint(false);
            document.removeEventListener('click', handleClick);
          } catch (err) {
            console.error('Audio playback failed:', err);
          }
        };
        
        document.addEventListener('click', handleClick);
        
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }
    };

    playAudio();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  const YesButton = ({ index }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isHovered, setIsHovered] = useState(false);

    const moveButton = () => {
      if (!isHovered) {
        const newX = Math.random() * 200 - 100; // Reduced range for mobile
        const newY = Math.random() * 200 - 100;
        setPosition({ x: newX, y: newY });
        setIsHovered(true);
        setTimeout(() => setIsHovered(false), 100);
      }
    };

    return (
      <button
        className="absolute bg-amber-400 text-zinc-800 px-4 sm:px-8 py-2 sm:py-3 rounded-full shadow-lg transform transition-all duration-200 hover:scale-105 text-sm sm:text-base"
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        onMouseEnter={moveButton}
        onClick={moveButton}
      >
        Yes
      </button>
    );
  };

  return (
    <div
      className="min-h-screen bg-gradient-to-b from-amber-50 to-amber-400 flex flex-col items-center justify-center p-4"
      onMouseMove={handleMouseMove}
    >
      {/* Sound control button - fixed position */}
      <button
        onClick={toggleMute}
        className="fixed top-4 right-4 z-50 bg-zinc-800 text-amber-400 p-2 rounded-full shadow-lg hover:bg-zinc-700 transition-all duration-200"
      >
        {/* Placeholder for sound icon - replace with your icon */}
        <div className="w-6 h-6">
          {isMuted ? "🔇" : "🔊"}
        </div>
      </button>

      {/* Main content container */}
      <div className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl space-y-6 sm:space-y-8 text-center px-4">
        {/* Image container with click hint */}
        <div className="relative bg-white p-3 sm:p-4 rounded-lg shadow-xl mb-4 sm:mb-8">
          <img
            src="/images/pookie (1).webp"
            alt="Apology"
            className="w-48 h-48 sm:w-64 sm:h-64 object-cover rounded mx-auto"
          />
          {showClickHint && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded text-white">
              <div className="text-center">
                <p className="text-lg sm:text-xl font-bold">Click anywhere</p>
                <p className="text-sm sm:text-base">to start the music 🎵</p>
              </div>
            </div>
          )}
        </div>

        {/* Question text */}
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-zinc-800 mb-4 sm:mb-6">
          Are you still upset with me?
        </h1>

        {/* Buttons container */}
        <div className="relative h-32 sm:h-48 w-full">
          {/* Yes buttons that move away */}
          <div className="grid grid-cols-2 gap-2 sm:gap-4 absolute inset-0 flex items-center justify-center">
            {[0, 1, 2].map((index) => (
              <YesButton key={index} index={index} />
            ))}
          </div>

          {/* Static No button */}
          <button
            onClick={handleNoClick}
            className="absolute left-1/2 transform -translate-x-1/2 bg-zinc-800 text-amber-400 px-4 sm:px-8 py-2 sm:py-3 rounded-full shadow-lg hover:bg-zinc-700 transition-all duration-200 hover:scale-105 text-sm sm:text-base"
          >
            No
          </button>
        </div>

        {/* Countdown Section */}
        <div className="mt-8 sm:mt-12">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-zinc-700 mb-2 sm:mb-4">
            Countdown to your birthday 🎉
          </h2>
          <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-zinc-800 font-bold">{countdown}</p>
        </div>

        {/* Countdown options */}
        <div className="mt-8 sm:mt-16 space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center items-center">
          <button
            className="w-full sm:w-auto bg-zinc-800 text-amber-400 px-4 sm:px-6 py-2 sm:py-3 rounded-full shadow-lg hover:bg-zinc-700 transition-all duration-200 hover:scale-105 text-sm sm:text-base"
            onClick={() => router('/2')}
          >
            See your birthday collage
          </button>
          <button
            className="w-full sm:w-auto bg-zinc-800 text-amber-400 px-4 sm:px-6 py-2 sm:py-3 rounded-full shadow-lg hover:bg-zinc-700 transition-all duration-200 hover:scale-105 text-sm sm:text-base"
            onClick={() => alert('See you at your birthday, pookie!')}
          >
            Wait until countdown is over
          </button>
        </div>
      </div>

      {/* Sparkle Effect */}
      <div className="fixed inset-0 pointer-events-none">
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-white rounded-full animate-pulse"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 2}s`,
              opacity: 0.3,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ApologyPage;