import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, PartyPopper, Gift, Cake, Star, Sparkles, Music } from 'lucide-react';
import Home from './components/home'
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import BirthdayLanding from './components/home2';
import ApologyPage from './components/ApologyPage ';
// Content Arrays - To be replaced with actual content later
const CONTENT = {
  images: [
    'pic1.jpg',
  'pic2.jpg','pic3.jpg','pic4.jpg','pic5.jpg','pic6.jpg','pic7.jpg','pic8.jpg',
  ],
  quotes: [
    'Quote 1: Special memory or message will go here',
    'Quote 2: Another meaningful message will be placed here',
    'Quote 3: Perhaps a funny story or memory belongs in this spot',
    'Quote 4: A heartfelt wish could go here',
    'Quote 5: Maybe a shared memory fits here',
    'Quote 6: A final celebration message to end with'
  ]
};

// Decorative Line Component
const BirthdayThemeLine = () => {
  const icons = [PartyPopper, Gift, Cake, Star, Sparkles, Music];
  
  return (
    <div className="fixed top-0 left-0 right-0 z-50 pointer-events-none">
      <div className="relative h-16">
        <div className="absolute top-8 left-0 right-0 h-1 bg-orange-500" 
             style={{
               clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%, 50% 0, 75% 50%, 100% 0, 125% 50%)"
             }} />
        
        <div className="absolute top-0 left-0 right-0 flex justify-around">
          {[...Array(12)].map((_, index) => {
            const Icon = icons[index % icons.length];
            const isEven = index % 2 === 0;
            return (
              <div
                key={index}
                className={`transform ${isEven ? 'translate-y-2' : 'translate-y-8'} transition-transform duration-1000`}
                style={{
                  animation: `float ${2 + (index % 3)}s ease-in-out infinite alternate`
                }}
              >
                <Icon
                  size={24}
                  className={`${index % 2 === 0 ? 'text-orange-500' : 'text-white'}`}
                />
              </div>
            );
          })}
        </div>
      </div>
      
      <style jsx global>{`
        @keyframes float {
          0% { transform: translateY(${Math.random() * 5}px); }
          100% { transform: translateY(-${Math.random() * 5}px); }
        }
      `}</style>
    </div>
  );
};

// Countdown Component
const Countdown = ({ onComplete }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const targetDate = new Date('2024-11-17T13:45:00');

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        onComplete();
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [onComplete]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-orange-500 p-4">
      <BirthdayThemeLine />
      <h1 className="text-4xl md:text-6xl font-bold mb-12">Birthday Countdown</h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="flex flex-col items-center">
            <div className="text-5xl md:text-7xl font-bold mb-2">
              {String(value).padStart(2, '0')}
            </div>
            <div className="text-xl md:text-2xl capitalize">{unit}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Page Component
const Page = ({ imageName, quote, index }) => {
  const background = index % 2 === 0 ? "bg-orange-500 text-black" : "bg-black text-orange-500";
  
  return (
    <div className={`w-screen h-screen flex-shrink-0 snap-start ${background} p-8 flex flex-col items-center justify-center`}>
      <div className="max-w-2xl text-center mt-16">
        {/* Placeholder image - replace src with actual image path later */}
        <img  
          src={`/images/pic${index + 1}.jpg`}
          alt={`Birthday moment ${index + 1}`}
          className="mx-auto mb-8 rounded-lg shadow-lg"
          width={"200px"} height={"50px"} 
        />
        <p className="text-2xl md:text-3xl italic">{quote}</p>
        {/* Optional: Display image name for development */}
      </div>
    </div>
  );
};

// Navigation Component
const Navigation = ({ currentPage, totalPages, onPageChange }) => (
  <div className="fixed bottom-8 left-0 right-0 flex justify-center items-center gap-4 z-50">
    <button 
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 0}
      className="p-2 bg-black/50 text-white rounded-full disabled:opacity-50"
    >
      <ChevronLeft size={24} />
    </button>
    <div className="flex gap-2">
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`w-3 h-3 rounded-full transition-all ${
            currentPage === i ? 'bg-orange-500 scale-125' : 'bg-white/50'
          }`}
        />
      ))}
    </div>
    <button 
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages - 1}
      className="p-2 bg-black/50 text-white rounded-full disabled:opacity-50"
    >
      <ChevronRight size={24} />
    </button>
  </div>
);

// Main App Component
const App = () => {
  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    const container = document.getElementById('pages-container');
    if (container) {
      container.scrollTo({
        left: pageIndex * window.innerWidth,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = (e) => {
    const newPage = Math.round(e.target.scrollLeft / window.innerWidth);
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  };

  if (!isCountdownComplete) {
    return <Countdown onComplete={() => setIsCountdownComplete(true)} />;
  }

  return (
    // <div className="relative overflow-hidden">
    //   <BirthdayThemeLine />
    //   <div
    //     id="pages-container"
    //     className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
    //     onScroll={handleScroll}
    //     style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
    //   >
    //     {CONTENT.images.map((imageName, index) => (
    //       <Page 
    //         key={index}
    //         imageName={imageName}
    //         quote={CONTENT.quotes[index]}
    //         index={index}
    //       />
    //     ))}
    //   </div>
    //   <Navigation
    //     currentPage={currentPage}
    //     totalPages={CONTENT.images.length}
    //     onPageChange={handlePageChange}
    //   />
    // </div>
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home></Home>}></Route>
        <Route path="/2" element={<BirthdayLanding></BirthdayLanding>}></Route>
        <Route path='/' element={<ApologyPage></ApologyPage>}></Route>

      </Routes>
      </BrowserRouter>
      </div>
  );
};

export default App;