import React, { useEffect, useState } from 'react';

const BirthdayLanding = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Sample photo data with captions
  const photos = [
    {src:"/images/pic1.jpg", id: 1, alt: "-", caption: "Pookie fzgat b chta", date: "December 2023" },
    {src:"/images/pic2.jpg", id: 2, alt: "-", caption: "Pookie pixles face preparing for exams", date: "January 2024" },
    { src:"/images/pic3.jpg",id: 3, alt: "Birthday memory 3", caption: "Pookie after a day of passing her exams", date: "January 2024" },
    {src:"/images/pic4.jpg", id: 4, alt: "Birthday memory 4", caption: "Pookie got her own 05 (slay queen)", date: "March 2024" },
    { src:"/images/pic5.jpg",id: 5, alt: "Birthday memory 5", caption: "Pookie keeping her lips hydrated(not for me 😔💔)", date: "March 2024" },
    { src:"/images/pic6.jpg",id: 6, alt: "Birthday memory 6", caption: "Pookie doing num num to go pass wisd", date: "Sepmtember 2024" },
    { src:"/images/pic9.jpg",id: 7, alt: "Birthday memory 6", caption: "Pookie happy with her flowers she got into BDSI", date: "October 2024" },
    { src:"/images/pic8.jpg",id: 8, alt: "Birthday memory 6", caption: "Pookie hot very demure in la fac", date: "July 2024" },
    { src:"/images/pic7.jpg",id: 9, alt: "Birthday memory 6", caption: "Pookie hot very demure in House", date: "July 2024" },
    { src:"/images/pic10.jpg",id: 10, alt: "Birthday memory 6", caption: "Pookie hot very demure in work(hard life)", date: " 2002-Present" },



  ];

// Floating balloon component with sparkle effect
const FloatingBalloon = ({ delay, initialX }) => (
    <div 
      className="absolute transition-transform duration-300"
      style={{ 
        transform: `translateY(${scrollY * 0.5}px)`,
        left: initialX,
        animationDelay: `${delay}s`
      }}
    >
      <div className="relative">
        <img  
          className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 object-contain" 
          src='/images/ballons.webp' 
          alt="Floating balloon"
        />
      </div>
    </div>
  );
  return (
    <div className="relative min-h-screen bg-gradient-to-b from-zinc-100 to-amber-100">
      {/* Floating Balloons */}
      <div className="fixed top-0 w-full h-32 z-50 pointer-events-none">
        <FloatingBalloon delay={0} initialX="20%" />
        <FloatingBalloon delay={0.2} initialX="40%" />
        <FloatingBalloon delay={0.4} initialX="60%" />
        <FloatingBalloon delay={0.6} initialX="80%" />
      </div>

      {/* Hero Section */}
      <section className="relative h-screen flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-amber-50 to-amber-400">
          {/* Sparkle Effect */}
          <div className="absolute inset-0 opacity-30">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="absolute w-1 h-1 bg-white rounded-full animate-pulse"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 2}s`
                }}
              />
            ))}
          </div>
        </div>
        
        <div className="relative z-10 text-center px-4 space-y-4">
          <h1 className="text-5xl md:text-7xl font-bold text-zinc-800 mb-4 font-serif">
            Happy Birthday
          </h1>
          <p className="text-2xl md:text-3xl text-zinc-700 font-light">
            to you...HAJAR bnt Si HASSAN aka POOKIE
          </p>
        </div>
      </section>

      {/* Photo Album Section */}
      <section className="px-4 py-16 bg-gradient-to-b from-amber-100 to-zinc-100">
        <h2 className="text-3xl font-bold text-center mb-12 text-zinc-800">
          Precious Memories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {photos.map((photo) => (
            <div 
              key={photo.id}
              className="group aspect-square relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 bg-white p-2"
            >
              <img
                src={photo.src}
                alt={photo.alt}
                className="w-full h-full object-cover rounded-lg group-hover:scale-105 transition-transform duration-300"
              />
              {/* Caption Overlay */}
              <div className="absolute inset-x-2 bottom-2 rounded-lg bg-gradient-to-t from-zinc-900/90 to-transparent p-4 translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                <div className="relative z-10">
                  <h3 className="text-amber-400 font-semibold text-lg">
                    {photo.caption}
                  </h3>
                  <p className="text-zinc-200 text-sm">
                    {photo.date}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
{/* ///div */}
<section className="px-4 py-16 bg-gradient-to-b from-amber-100 to-zinc-100">

<div
      className={`max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 cursor-pointer transition-all ${
        isOpen ? 'h-auto' : 'h-36'
      }`}
      onClick={toggleCard}
    >
      <div className="px-6 py-4">
        <h2 className="text-xl font-semibold text-gray-800">🎉 Happy Birthday! pookie🎂</h2>
        <p className="text-gray-600 mt-2">
          {isOpen
            ? `Wishing you a day filled with happiness and a year filled with joy.you get to see this early just beceause i made pookie angry!`
            : `Tap to open your birthday card!`}
        </p>
      </div>
      {isOpen && (
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <p className="text-gray-700">
            Enjoy every moment of your special day! Here’s to more cake, candles, and memories! 🎁✨
          </p>
        </div>
      )}
    </div>
    </section>
      {/* Footer */}
      <footer className="bg-zinc-800 py-8 text-center">
        <p className="text-amber-400">Celebrating Your Special Day</p>
      </footer>
      
    </div>
  );
};

export default BirthdayLanding;