import React, { useEffect, useState } from 'react';

const Countdown = ({ targetDate, onCountdownEnd }) => {
  const [timeLeft, setTimeLeft] = useState({});
  //const time =sessionStorage.setItem('isTime') ='false';
  const [isTime, setIsTime] = useState(
    sessionStorage.getItem('isTime') === 'true' // Initialize from sessionStorage
  );

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        onCountdownEnd(); // Notify when countdown ends
        setIsTime(true); // Update local state
        sessionStorage.setItem('isTime', 'true'); // Update session variable
        return {};
      }

      const time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      return time;
    };

    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (!Object.keys(time).length) clearInterval(timer); // Stop timer when complete
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, onCountdownEnd]);

  return { timeLeft, isTime };
};

const Home = () => {
  const targetDate = new Date("2024-11-17T00:00:00"); // Replace with your desired target date
  const { timeLeft, isTime } = Countdown({
    targetDate,
    onCountdownEnd: () => console.log("Countdown Ended"),
  });

  return (
    <div>
      {/* First Section: Hero Image */}
      <div className="hero">
        <img className="h-screen w-screen" src="/images/6236183_22492.jpg" alt="Hero" />
      </div>

      {/* Second Section: Countdown or Message */}
      <div className="flex flex-col items-center justify-center text-white text-center h-screen bg-black">
        {isTime ? (
          <h1 className="text-4xl font-bold">🎉 The Countdown is Over! 🎉</h1>
        ) : (
          <>
            <h1 className="text-4xl font-bold mb-4">Countdown to Birthday</h1>
            <div className="flex space-x-4 text-2xl">
              <div>
                <span className="block font-bold">{timeLeft.days}</span>
                <span>Days</span>
              </div>
              <div>
                <span className="block font-bold">{timeLeft.hours}</span>
                <span>Hours</span>
              </div>
              <div>
                <span className="block font-bold">{timeLeft.minutes}</span>
                <span>Minutes</span>
              </div>
              <div>
                <span className="block font-bold">{timeLeft.seconds}</span>
                <span>Seconds</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
